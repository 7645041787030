<template>
<div>

  <div class="main-class">
    <div class="box1">
      <el-carousel>
        <el-carousel-item v-for="item in imagesbox" :key="item.id" >
          <div class="pic_item">
          <img :src="item.imgView">
          <div class="title">{{item.titl}}</div>
          <div class="note">{{item.note}}</div>
          <el-button >了解更多</el-button>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
        <section class="zhong"> 
            <div class="am-container">
              <el-row class="box2">
                <el-col :span="6">
                  <img src="../assets/img/icon1.png">
                  <div style="margin:20px 0 ">客户营销一体化</div>
                </el-col>
                <el-col :span="6">
                  <img src="../assets/img/icon2.png">
                  <div style="margin:20px 0 ">生产调度一体化</div>
                </el-col>
                <el-col :span="6">
                  <img src="../assets/img/icon3.png">
                  <div style="margin:20px 0 ">大数据一体化</div>
                </el-col>
                <el-col :span="6">
                  <img src="../assets/img/icon4.png">
                  <div style="margin:20px 0 ">水环境监测</div>
                </el-col>
              </el-row>
            </div>
        </section>
        <section class="pt"> 
            <div class="am-container">
                <div class="ue-title">
                    <p>公司简介</p>
                    <p class="note">中海创是国内领先的跨行业、跨领域的工业互联网解决方案提供商、平台运营商、智能制造咨询服务商</p>
                </div>
                <div class="pt-list">
                  <el-row>
                    <el-col class="box3" :span="12">
                      <img src="../assets/img/p1.png" >
                    </el-col>
                    <el-col class="box3" :span="12">
                      <div class="title">中海创水务科技（福建）有限公司简介</div>
                      <div class="note">{{this.$parent.data.company_blurb}}</div>
                      <el-button type="danger" round class="button" @click="go">了解更多</el-button>
                    </el-col>
                  </el-row>
                </div>
            </div>
        </section>
        <section class="portal"> 
            <div class="am-container">
                <div class="ue-title">
                    <p>智慧水务统一门户平台</p>
                    <p class="note">基于新一代互联网技术为用户提供智能化服务，以全新商业智慧、全新智能服务，帮助用户实现数字化转型和智能化的发展。</p>
                </div>
                <div class="pt-list">
                    <ul class="am-avg-md-3">
                        <li>
                            <i><img src="~/images/2b7ecc146049488d.png"></i>
                            <div class="txt">
                                <p class="tit">IT集约化管理</p>
                                <p class="note">能集中对各类IT设施与信息化资源进行整合，实现资源的统一配置</p>
                            </div>
                        </li>
                        <li>
                            <i><img src="~/images/监控监测.png"></i>
                            <div class="txt">  
                                <p class="tit">更强的民生服务能力</p>
                                <p class="note">一信息化接口，标准化服务流程，迅速跟上和满足现代化的服务能力要求</p>
                            </div>
                        </li>
                        <li>
                            <i><img src="~/images/设备管理.png"></i>
                           <div class="txt">
                                <p class="tit">新一轮水务运营新模式</p>
                                <p class="note">基于大数据、云计算的运营方式，能在行业中树立极强的典范效应，为品牌的拓展与延伸建立更加好的基础</p>
                            </div>
                        </li>
                        <li>
                          <i><img src="~/images/状态监管.png"></i>
                            <div class="txt">
                                   <p class="tit">业务集中化管控</p>
                                   <p class="note">供水、排水、污水、水环境等实现业务的统一管控、统一标准</p>
                               </div>
                           </li>
                           <li>
                               <i><img src="~/images/可视化管理.png"></i>
                               <div class="txt">
                                   <p class="tit">强大的生态大数据中心</p>
                                   <p class="note">将水务企业的生产、运营、服务数据统一汇聚，为运营分析、行业研究提供极佳数据平台</p>
                               </div>
                           </li>
                           <li>
                               <i><img src="~/images/大数据服务.png"></i>
                               <div class="txt">
                                   <p class="tit">为智能水务运营提供支撑</p>
                                   <p class="note">新模式、新技术、新应用都能依托于云平台的模式，植入到运营中，为智能化发展提供技术支撑</p>
                               </div>
                           </li>
                       </ul>
                   </div>
            </div>
        </section>
        <section class="zh-sys">
            <div class="am-container">
            <div class="txt">
                <p class="tit">智慧水务客户营销服务一体化平台</p>
                <p class="note">根据客服营销服务需求，建设水务客户营销服务一体化平台，围绕营销、收费、服务业务展开，支持有多个业务系统同时运行，涉及工程报装、营业收费、表务管理、安防监控管理、直饮水管理、客户呼叫管理、微信公众号平台、网上营业厅、短信中心、移动抄表、电子发票管理、第三方收费、终端机、数据维护和外部数据接口等一体化业务的综合服务管理系统平台。水务客户营销服务一体化平台将进一步提升业务办理效率，提高服务质量，提高用户满意度。</p>
            </div>
            <div class="zheys-list">
                <div class="teck-advantage-center">
                    <i>
                        <img id="img3" src="~images/yuan.png">
                    </i>
                </div>
                <ul>
                    <li class="sb1">
                        <a>
                            <i><img src="../assets/img/icon10.png" /></i>
                            <div class="tex">
                                <p class="tit">营业收费管理系统</p>
                                <p class="note">供水企业营业收费管理系统源于对供水企业业务管理需求的深入理解，系统在实践中不断优化升级，日臻完善，可灵活响应百万级用户以上特大型和大、中、小型供水企业营收管理需求。</p>
                            </div>
                        </a>
                    </li>
                    <li class="sb2">
                        <a>
                            <i><img src="../assets/img/icon12.png" /></i>
                            <div class="tex">
                                <p class="tit">报装管理系统</p>
                                <p class="note">中海创水务科技供水行业报装系统便于供水企业用户发展和管网建设工程管理工作的智能化、信息化管理；可完善流程管理，界定相关职责，规范化立户报装业务，大大提高供水报装业务服务质量和工作效率。</p>
                            </div>
                        </a>
                    </li>
                    <li class="sb3">
                        <a>
                            <i><img src="../assets/img/icon13.png" /></i>
                            <div class="tex">
                                <p class="tit">智能计量管理系统</p>
                                <p class="note">以大数据智慧水务理念构建流量表数据监控分析系统，帮助供水企业规范抄表工作、普及智能表具、完善表具设备管理、增强流量信息监控和智能分析。</p>
                            </div>
                        </a>
                    </li>
                    <li class="sb4">
                        <a>
                            <i><img src="../assets/img/微信.png" /></i>
                            <div class="tex">
                                <p class="tit">供水企业微信服务系统</p>
                                <p class="note">供水企业微信服务系统是一个专门帮助水司便捷搭建、运营微信公众账号的服务工具。</p>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>
            </div>
        </section>
        <section class="zh-sys1">
            <div class="am-container">
            <div class="txt">
                <p class="tit">智慧水务生产经营一体化平台</p>
                <p class="note">水务生产关键环节的实时监测，基于供排水管网，展现供水的水质、水量、水压实时监测；排水的排污口、考核断面、窨井水位实时监测；厂泵站工艺流程实时监测；视频实时监测，方便领导了解水务集团主要业务整体运营情况，为业务运营决策提供依据。实现“分析诊断、决策分析、执行监控、效果反馈”管理服务一体化闭环流程，以数据洞察驱动流程优化和管理提升。</p>
            </div>
            <div class="zheys-list1">
                <div class="teck-advantage-center">
                    <i>
                        <img id="img3" src="../assets/yuan.png">
                    </i>
                </div>
                <ul>
                    <li class="sb1">
                        <a>
                            <i><img src="../assets/img/icon14.png" /></i>
                            <div class="tex">
                                <p class="tit">供水产销差漏损控制系统</p>
                                <p class="note">由小区级总分表差监控系统、DMA分区漏损监控系统、供水大用户管理系统组成；功能包含：智能计量表档案管理、数据采集、DMA分区规划设立、数据展示、KPI数据分析、分析报警、报警派工、工单管理等主要模块，其形成了一套数据采集、数据分析、报警处理、报警派工的完整管理闭环体系。系统同时也具备APP、微信消息推送等移动化能力。</p>
                            </div>
                        </a>
                    </li>
                    <li class="sb2">
                        <a>
                            <i><img src="../assets/img/icon15.png" /></i>
                            <div class="tex">
                                <p class="tit">水务调度系统</p>
                                <p class="note">水务调度系统是一个综合的供水信息化管理平台，可以将水务公司管辖下的供排水泵站、水源井、水厂、污水厂、加压泵站、提升泵站、管网等重要供水单元纳入全方位的监控和管理。借助水务调度系统，调度中心可远程监测各监测单元的实时生产数据和设备运行参数；可远程查看重要生产部位的监控视频或监控照片；可远程管理泵、阀、表等设备。</p>

                            </div>
                        </a>
                    </li>
                    <li class="sb3">
                        <a>
                            <i><img src="../assets/img/icon11.png" /></i>
                            <div class="tex">
                                <p class="tit">二次供水系统</p>
                                <p class="note">智慧管理模式下的二次加压泵房，具有更加清洁，节能，智能的供水系统。依托高效的水务物联网，在调度中心便可用精细化，动态化的可视方式实时查看二次加压泵房的水质、水压、流量、水箱液位等供水信息，也可对二次加压泵房中补水系统、供水系统、排污系统、安防系统等所有设备运行状态实时监测调控。</p>
                            </div>
                        </a>
                    </li>
                    <li class="sb4">
                        <a>
                            <i><img src="../assets/img/icon16.png" /></i>
                            <div class="tex">
                                <p class="tit">水务巡检系统</p>
                                <p class="note">管网巡检管理是有效保证供排水管道及其附属设备安全平稳运行的一项基础性工作，对管网的巡视检查，发现管道设备故障以及危害线路安全的安全隐患、预防生产事故的发生、保证供排水管道的平稳安全运行，发挥着重要作用。 </p>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>
            </div>
        </section>
        <section class="xw"> 
            <div class="am-container">
                <div class="ue-title">
                    <p>新闻中心</p>
                </div>
                <div class="pt-list">
                  <el-row :gutter="20">
                    <el-col class="box3" :span="8" v-for="item in dataList1" :key="item.fid" >
                        <a href="javescript::"  style="text-decoration: none;">
                      <img :src="/zhcwaterweb/+item.furl"  @click="go2(item)">
                      <div class="text" @click="go2(item)">{{item.article_blurb}}</div>
                        </a>
                    </el-col>
                    <!-- <el-col class="box3" :span="8">
                      <img src="../assets/img/p3.png" >
                      <div class="text">我司以发展自身技术能力为核心，秉承“技术领先、做精产品”的研发</div>
                    </el-col>
                    <el-col class="box3" :span="8">
                      <img src="../assets/img/p4.png" >
                      <div class="text">我司以发展自身技术能力为核心，秉承“技术领先、做精产品”的研发</div>
                    </el-col> -->
                    <el-col :span="24">
                        <el-button @click="go1" type="primary" round>了解更多</el-button>
                    </el-col>
                  </el-row>
                </div>
            </div>
        </section>
</div>
</div>
</template>

<script>
import {getList}from 'api/product'
import {
  getListxw,
} from "api/xw";
export default {
  data () {
    return {
      imagesbox: [
        {
          id:0,imgView: require('../assets/banner/banner1.png'),href:'/Product',titl:'智慧物联 智慧水务',note:'领先的智慧水务系列产业和技术服务皆为高效安全的水管理',
        }   ,     {
          id:1,imgView: require('../assets/banner/banner1_2.png'),href:'/Product',titl:'智慧水务·客户营销服务一体化平台',note:'一体化业务的综合服务管理系统平台，围绕营销、收费、服务业务展开，支持多个业务系统同时运行。提升业务办理效率，提高用户满意度。',
        }   ,     {
          id:2,imgView: require('../assets/banner/banner1_3.png'),href:'/monitor',titl:'智慧水务·统一门户',note:'一个账号，登录多个系统，实现应用系统的无缝对接。',
        }   ,     {
          id:3,imgView: require('../assets/banner/banner1_4.png'),href:'/monitor',titl:'智慧水务·生产经营一体化平台',note:'水务生产关键环节的实时监测，“分析诊断、决策分析、执行监控、效果反馈”，生产、经营一体化闭环流程，以数据洞察驱动流程优化和管理提升。',
        }   ,   
      ],
      searchInfo: {
        pageNo: 1,
        pageSize: 8,
      },
      
    searchInfo1: {
        pageNo: 1,
        pageSize: 3,
        order_by:'add_time desc'
      },
      solutionimgbox:[
        {imgview:require('../assets/images/组-58.png'),pp: '智慧用电监测系统',icon:require('../assets/images/智慧用电w.png'), note:'一、概述随着社会信息化程度的不断提高，动力环境计算机系统的数量与日俱增，其环境设备也日益增多，动力环境环境设备（如…'},
        {imgview:require('../assets/images/组-59.png'),pp: '火灾报警及联动控制系统',icon:require('../assets/images/电气火灾系统w.png'), note:'一、概述随着社会信息化程度的不断提高，动力环境计算机系统的数量与日俱增，其环境设备也日益增多，动力环境环境设备（如…'},
        {imgview:require('../assets/images/组-60.png'),pp: '智慧消防栓监测系统',icon:require('../assets/images/消防栓系统w.png'), note:'一、概述随着社会信息化程度的不断提高，动力环境计算机系统的数量与日俱增，其环境设备也日益增多，动力环境环境设备（如…'},
        {imgview:require('../assets/images/组-57.png'),pp: '配电房环境监测系统',icon:require('../assets/images/环境监测系统w.png'), note:'一、概述随着社会信息化程度的不断提高，动力环境计算机系统的数量与日俱增，其环境设备也日益增多，动力环境环境设备（如…'},
        {imgview:require('../assets/images/组-58.png'),pp: '智慧用电监测系统',icon:require('../assets/images/智慧用电w.png'), note:'一、概述随着社会信息化程度的不断提高，动力环境计算机系统的数量与日俱增，其环境设备也日益增多，动力环境环境设备（如…'},
        {imgview:require('../assets/images/组-59.png'),pp: '火灾报警及联动控制系统',icon:require('../assets/images/电气火灾系统w.png'), note:'一、概述随着社会信息化程度的不断提高，动力环境计算机系统的数量与日俱增，其环境设备也日益增多，动力环境环境设备（如…'},
        {imgview:require('../assets/images/组-60.png'),pp: '智慧消防栓监测系统',icon:require('../assets/images/消防栓系统w.png'), note:'一、概述随着社会信息化程度的不断提高，动力环境计算机系统的数量与日俱增，其环境设备也日益增多，动力环境环境设备（如…'},
        {imgview:require('../assets/images/组-57.png'),pp: '配电房环境监测系统',icon:require('../assets/images/环境监测系统w.png'), note:'一、概述随着社会信息化程度的不断提高，动力环境计算机系统的数量与日俱增，其环境设备也日益增多，动力环境环境设备（如…'},
        {imgview:require('../assets/images/组-58.png'),pp: '智慧用电监测系统',icon:require('../assets/images/智慧用电w.png'), note:'一、概述随着社会信息化程度的不断提高，动力环境计算机系统的数量与日俱增，其环境设备也日益增多，动力环境环境设备（如…'},
        {imgview:require('../assets/images/组-59.png'),pp: '火灾报警及联动控制系统',icon:require('../assets/images/电气火灾系统w.png'), note:'一、概述随着社会信息化程度的不断提高，动力环境计算机系统的数量与日俱增，其环境设备也日益增多，动力环境环境设备（如…'},
      ],
      dataList:[],
      dataList1:[],
      timer:'',
      dialogVisible: false,
      solutiondialogVisible: false,

    }
  },
   methods: {
        getList1() {
        getListxw(this.searchInfo1).then((res) => {
        this.dataList1 = res.data.list;
      });},
        getList() {
        this.isShowloading = true;
         console.log(this.searchInfo);
        getList(this.searchInfo).then((res) => {
        console.log(res);
        this.dataList = res.data.list;
        console.log(this.dataList)
         console.log(this.modelForm)
      });},        
        func(item) {
        this.$router.push({name: 'product-2',params:{ id:item.fuid}});
      },
        getnext() {
          let list = document.getElementById('list')
          let left = parseInt(list.style.left)
          clearInterval(this.timer);        
        this.timer = setInterval(this.getnext(),3000)
          if( left == -1800  ) {
            list.style.left = -900 +'px'
          }else {
              list.style.left = parseInt(list.style.left) - 300 + 'px'
            }

          },    
          getlast() {
            let list = document.getElementById('list')
            let left = parseInt(list.style.left)
            clearInterval(this.timer);        
          this.timer = setInterval(this.getnext(),3000)
            if (left == -900) {
              list.style.left = -1800 + 'px'
            }else {
            list.style.left = parseInt(list.style.left) + 300 + 'px'
              
            }
          },
          func1() {
            this.$router.push({name: 'solution'});
          },
          go(){
            this.$router.push({name: 'about'});
          },
          go2(item1){
            // let pathInfo = this.$router.resolve({
            //     path: '/Journalism1',
            //     query: {
            //         id: item1.fuid 
            //     }
            // })
            window.open(item1.article_url,'_blank')
            // this.$router.push({name: 'Journalism1',params:{ id:item1.fuid}});
          },
          go1(){
            this.$router.push({name: 'Journalism'});
          }

    },
    mounted() {
    this.getList()
    this.getList1()
    this.timer = setInterval(this.getnext(),3000)

},
    beforeDestroy() {
      clearInterval(this.timer)
    }
};
</script>
<style lang='scss' scope>
.main-class {
  .box1 {
    height:600px ;
    .el-carousel {
      height: 100%;
      img {
        z-index: 2;
        width: 100%;
        height: 600px;
      }
    }
  }
  .product {
    padding: 80px 0;
    .ue-title {
        font-size: 32px;
    }   
    .productList {
        margin-top: 20px;
        ul {
            display: -webkit-flex;
            display: flex;
            align-content: space-around;
            -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
            -webkit-align-content: center;
            align-content: center;
            justify-content: space-between;
        li {
            padding: 10px 0;
            list-style: none;
            width: 23%;
            a{
                display: block;
                background: #fff;
                text-align: center;
                border: 1px solid #fff;
                overflow: hidden;
                text-decoration: none;
                img {
                    transition: all .7s;
                    height: 175px;
                }
                
                img:hover {
                    transform: scale(1.2);
                    }
                .txt{
                    padding:20px;
                .tit {
                        font-size: 16px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        margin-bottom: 4px;
                        color: #333;
                }
                .note {
                        font-size: 12px;
                        margin-bottom: 6px;
                        color: #999;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        overflow: hidden;
                }}
            }
            a:hover {
                    border: 1px solid #0e90d2;
                }
        }
        }
    }
    .more {
        align-content: center;
        margin-top:30px ;
        a {
            display: inline-block;
            padding: 8px 60px;
            font-size: 20px;
            border: 1px solid #ddd;
            text-decoration: none;
        }        
        a:hover {
            background: #2278c7;
            color: #fff;
        }
    }
}}
.pt { 
    padding: 80px 0;
    background: #32333B;
        .ue-title {
        font-size: 32px;
        color: #FFFFFF;
        .note {
              font-size: 16px;
              color: #E9E9E9;
              word-break: break-all;
              word-wrap: break-word;
              white-space: pre-wrap;
              margin-top: 5px;
        }
    }  
        .pt-list {
              text-align: left;
            margin-top: 30px;
            .box3{
              height: 100%;
            }
            .title{
              color: #30B9F3;
              font-size: 20px;
            }
            .note{
              color: #E9E9E9;
              margin-top: 25px;
              font-size: 16px;
              line-height: 40px;
            }
            .button{
              margin-top: 50px;
            }
    
    }
}
// .portal{
//   margin-top: 40px;
//         .ue-title {
//         font-size: 32px;
//         color: #0F0F1A;
//         .note {
//               font-size: 16px;
//               color: #0F0F1A;
//               word-break: break-all;
//               word-wrap: break-word;
//               white-space: pre-wrap;
//               margin-top: 20px;
//         }}
// }
.xw{
    padding: 80px 0;
    background: #ffffff;
    
            img{
                max-width: 100%;
                height: 230px;
                width:100%
            }
        .ue-title {
          height: 40px;
          font-size: 32px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 40px;
          color: #0F0F1A;
          opacity: 1;
          text-align: center;

    }  
    .box3{
      margin: 40px 0 ;
      .text{
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 35px;
        color: #606060;
        opacity: 1;
        text-align: left;
        margin-top: 36px;
      }
    }

}
.box2{
  margin: 40px 0 ;
}
.am-container {
    position: relative;
    height: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
    width: 100%;
    max-width: 1230px;
}
.zh-sys {
    background-color: #233278;
    padding: 80px 0;
    ul {
        li:first-child {
            padding-right: 90px;
            text-align: right;
            margin-bottom: 120px;
                i{
                    right: 0;
                    top: 0;
                }
        }
        li:nth-child(2) {
                float: right;
                margin-bottom: 120px;
                padding-left: 90px;
                text-align: left;
                i{
                    left: 0;
                    top: 0;
                }
        }
        li:nth-child(3)    {
            padding-right: 90px;
             text-align: right;
                i{
                    right: 0;
                    top: 0;
                }
        }
        li:nth-child(4) {
                float: right;
                padding-left: 90px;
                text-align: left;
                i{
                    left: 0;
                    top: 0;
                }
        }
        li {
            width: 30%;
            float: left;
            position: relative;
            padding-top: 15px;
        }
    }
    .txt {
        .tit {
            font-size: 32px;
            color: #fff;
        }
        .note {
            font-size: 16px;
            color: rgb(224, 219, 219);
            color: #999;
            word-break: break-all;
            word-wrap: break-word;
            white-space: pre-wrap;
        }
    }
    .tex {
        display: block;
        .tit {
            font-size: 20px;
            transition: .2s all;
            color: #fff;
            cursor: pointer;
            
        }
        .note {
                color: rgba(255,255,255,.6);
             text-indent: 2em;
                font-size: 14px;
                text-align: left;
        }
    }
}
.zh-sys1 {
    background-color: #374585;
    padding: 80px 0;
    ul {
        li:first-child {
            padding-right: 90px;
            text-align: right;
            margin-bottom: 120px;
                i{
                    right: 0;
                    top: 0;
                }
        }
        li:nth-child(2) {
                float: right;
                margin-bottom: 120px;
                padding-left: 90px;
                text-align: left;
                i{
                    left: 0;
                    top: 0;
                }
        }
        li:nth-child(3)    {
            padding-right: 90px;
             text-align: right;
                i{
                    right: 0;
                    top: 0;
                }
        }
        li:nth-child(4) {
                float: right;
                padding-left: 90px;
                text-align: left;
                i{
                    left: 0;
                    top: 0;
                }
        }
        li {
            width: 30%;
            float: left;
            position: relative;
            padding-top: 15px;
        }
    }
    .txt {
        .tit {
            font-size: 32px;
            color: #fff;
        }
        .note {
            font-size: 16px;
            color: rgb(224, 219, 219);
            word-break: break-all;
            word-wrap: break-word;
            white-space: pre-wrap;
            
        }
    }
    .tex {
        display: block;
        .tit {
            font-size: 20px;
            transition: .2s all;
            color: #fff;
            cursor: pointer;
            
        }
        .note {
                color: rgba(255,255,255,.6);
             text-indent: 2em;
                font-size: 14px;
                text-align: left;
        }
    }
}
.am-container::after{
    content: ".";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.zheys-list {
    margin-top: 40px;
    .teck-advantage-center {
        position: absolute;
        top: 40%;
        left: 50%;
        width: 219px;
        height: 219px;
        margin-left: -110px;
        border-radius:50% ;
        background: url('../assets/img/2.png') center center no-repeat ;
        background-size:80% 80% ;
        i {
            display: block;
            height: 100%;
            width: 100%;
            img {
                animation:rot_test 3s infinite linear
            }
            @keyframes rot_test {
                0%{
                    transform: rotate(360deg);
                }    
                100%{
                    transform: rotate(0deg);
                }        
            }
        }
    }
    ul{
        li {
            i {
                position: absolute;
            }
            .sb2 .sb4 {
                i{
                left:0;
                top: 0;
                }
            }
            .sb1 .sb3 {
                right: 0;
                top: 0;
            }
            .note {
                font-size: 14px;
            }
        }
    }
}
.zheys-list1 {
    margin-top: 40px;
    .teck-advantage-center {
        position: absolute;
        top: 40%;
        left: 50%;
        width: 219px;
        height: 219px;
        margin-left: -110px;
        border-radius:50% ;
        background: url('../assets/img/1.png') center center no-repeat ;
        background-size:80% 80% ;
        i {
            display: block;
            height: 100%;
            width: 100%;
            img {
                animation:rot_test 3s infinite linear
            }
            @keyframes rot_test {
                0%{
                    transform: rotate(-180deg);
                }    
                100%{
                    transform: rotate(180deg);
                }        
            }
        }
    }
    ul{
        li {
            i {
                position: absolute;
            }
            .sb2 .sb4 {
                i{
                left:0;
                top: 0;
                }
            }
            .sb1 .sb3 {
                right: 0;
                top: 0;
            }
            .note {
                font-size: 14px;
            }
        }
    }
}
.portal { 
    padding: 80px 0;
        .ue-title {
        font-size: 32px;
        .note {
              font-size: 16px;
              color: #999;
              word-break: break-all;
              word-wrap: break-word;
              white-space: pre-wrap;
              margin-top: 5px;
        }
    }  
        .pt-list {
            margin-top: 30px;
            
        ul {
            display: -webkit-flex;
            display: flex;
            align-content: space-around;
            -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
            -webkit-align-content: center;
            align-content: center;
            justify-content: space-between;
        li {
            padding: 10px 0;
            list-style: none;
            width: 30%;
            display: block;
            text-align: center;
            margin-bottom: 25px;
                .txt{
                    // padding:20px;
                .tit {
                        font-size: 20px;
                        margin-top: 5px;
                        margin-bottom:15px;
                }
                .note {    
                    font-size: 14px;
                    color: #999;
                    max-width: 78%;
                    margin: 0 auto;
                }
            }
        }
        li:hover {
          box-shadow:0 1px 2px rgba(0,0,0,.1)
        }
        }
    
    }
}
.pic_item{
  .title{
    color:#FFFFFF ;
    font-size: 32px;
    top: 32%;
    left: 17%;
    position: absolute;
  }
  .note{
    color: #FFFCFC ;
    font-size: 18px;
    width: 500px;
    text-align: left;
    top: 45%;
    left: 17%;
    position: absolute;
  }
}
</style>

<style>
.el-carousel__container {
  height: 100%;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
.pic_item .el-button{
  position: absolute;
    left: 17%;
  top: 65%;
  background-color:#255BD9 ;
  color: #fff;
  border: #255BD9;

}
</style>